import * as React from 'react';
import Slider from "../Slider/slider";
import LatestNews from "./latestNews";

const FrontPage = (props) => {
    return (
        <>
            <Slider lang={props.lang}/>
            <div className="container">
            <LatestNews lang={props.lang} showModal={props.showModal} />
            </div>
        </>
    )
}

export default FrontPage;