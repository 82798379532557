import React, {
    useState,
    useContext,
    useMemo,
    useEffect,
    createContext,
    Suspense,
    lazy
} from 'react';
import ReactDOM from 'react-dom';
import {LinearProgress} from "@material-ui/core";

function delay(promise, time) {
    return promise.then(
        result => new Promise(resolve => setTimeout(() => resolve(result), time))
    );
}


const IndicatorContext = createContext();

function IndicatorProvider({ children }) {
    const [active, setActive] = useState(false);
    const value = useMemo(() => ({ active, setActive }), [active, setActive]);
    return (
        <IndicatorContext.Provider value={value}>
            {children}
        </IndicatorContext.Provider>
    );
}

function Indicator() {
    const { active } = useContext(IndicatorContext);
    const [percent, setPercent] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setPercent(percent => (percent < 100 ? percent + 10 : 100));
        }, 200);
    });
    return active ? <LinearProgress percent={percent} /> : null;
}

function IndicatorFallback() {
    const { setActive } = useContext(IndicatorContext);
    useEffect(() => {
        setActive(true);
        return () => setActive(false);
    });
    return null;
}

export default IndicatorProvider;