import * as React from 'react';
import {useEffect, useState} from "react";
import "./article.css"
import ShareButton from 'react-social-share-buttons'
import ApiRepository from "../../repository/apiRepository";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {MouseHover} from "../UI/MouseHover";
import {Helmet} from "react-helmet";

const Article = (props) => {

    const {articleId} = useParams();
    const {t} = useTranslation();
    const [recommendedArticles, setRecommendedArticles] = useState([])
    const [latestArticles, setLatestArticles] = useState([])
    const [tags, setTags] = useState([])

    useEffect(() => {
        ApiRepository.getArticle(articleId).then((data) => {
            setArticle(data.data.content);
            setRecommendedArticles(data.data.trending);
            setLatestArticles(data.data.newest);
            setTags(data.data.tags)
        })
    }, [props.lang, articleId])

    const [article, setArticle] = useState({
        subTitle: "",
        name: "",
        cover: "/images/heroes/study_hero.jpg",
        tags: ["Keyword1", "Keyword2"],
        introduction: "This is long Introduction to the article",
        mainPhotoUrl: "https://klimatskipromeni.mk/data/rest/file/download/53bef70c2456d5d4477efcaebf5ce71b3c34f60770fea25e68968c5bc9dc4468.jpg",
        content: "This is <b>vold</b> content."
    })

    const tagItems = tags.map((item) => {
        return (
            <li><a href={`/keyword/${item}`}><MouseHover>{item}</MouseHover></a>
            </li>
        )
    })

    const trending = latestArticles.map(item => {
        return (
            <li>
                <h3>
                    <a href={item.link}><u><MouseHover>{item.title}</MouseHover></u></a>
                </h3>
                <strong>
                    <small>
                        <small>{moment(new Date(item.datePublished)).format("DD/MM/YYYY")}</small>
                    </small>
                </strong>
            </li>
        )

    })

    const recommended = recommendedArticles.map(item => {
        return (
            <li>
                <h3>
                    <a href={item.link}><u><MouseHover>{item.title}</MouseHover></u></a>
                </h3>
                <strong>
                    <small>{moment(new Date(item.datePublished)).format("DD/MM/YYYY")}</small>
                </strong>
            </li>
        )

    })

    console.log(props.match)

    return (
        <section>
            <Helmet>
                {/*Facebook and LinkedIn Meta tags*/}
                <meta property="og:title" content={article.name} />
                <meta property="og:type" content="article:author" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={article.mainPhotoUrl || article.imageUrl || article.coverImageUrl} />
                <meta property='og:description' content={article.subTitle} />
                <meta property="og:image:secure_url" content={article.mainPhotoUrl || article.imageUrl || article.coverImageUrl} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="300" />
                {/*Twitter Meta tags*/}
                <meta name="twitter:title" content={article.name} />
                <meta name="twitter:description" content={article.subTitle} />
                <meta name="twitter:image" content={article.mainPhotoUrl || article.imageUrl || article.coverImageUrl} />
                <meta name="twitter:card" content="summary_large_image"/>

            </Helmet>
            <div className="header-article"
                 style={{'background': `url(${article.coverImageUrl || '/images/heroes/news_hero.jpg'})`}}>
                <div className="container">
                </div>
            </div>
            <div className="bg-color-light">
                <div className="container content-sm">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="news-v3 bg-color-white mb-3 mt-3">

                                <div className="news-v3-in">

                                    <h2><MouseHover>{article.name}</MouseHover></h2>
                                    <h5 className="h5"><MouseHover>{article.subTitle}</MouseHover></h5>
                                    <hr/>
                                    <ul className="list-inline tags-v2">
                                        {tagItems}
                                    </ul>


                                    <br/>

                                    <blockquote className="hero">

                                        <p>
                                            <div><MouseHover>{article.introduction}</MouseHover></div>
                                        </p>
                                    </blockquote>

                                    <img className="img-fluid full-width" src={article.mainPhotoUrl}
                                         alt=""/>
                                    <div className="row">
                                        <div className="col-md-9 col-sm-8">
                                            <ul className="list-inline posted-info">


                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-sm-4 text-right">
                                            <ul className="article post-shares post-shares-lg ">
                                                <li>
                                                    <ShareButton
                                                        compact
                                                        socialMedia={'facebook'}
                                                        url={`https://klimatskipromeni.mk/article/${article.id}`}
                                                        media={article.mainPhotoUrl || article.imageUrl || article.coverImageUrl}
                                                        text={article.name}
                                                    />
                                                </li>
                                                <li>
                                                    <ShareButton
                                                        compact
                                                        socialMedia={'twitter'}
                                                        url={`https://klimatskipromeni.mk/article/${article.id}`}
                                                        media={article.mainPhotoUrl || article.imageUrl || article.coverImageUrl}
                                                        text={article.name}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div dangerouslySetInnerHTML={{__html: article.content}}></div>

                                    {/*<div>*/}
                                    {/*    <ul className="list-inline blog-photostream margin-bottom-50">*/}
                                    {/*        <li th:each="photo : ${contentPhotoSet}">*/}
                                    {/*            <a th:href="${photo.imageUrl}" data-fancybox="gallery"*/}
                                    {/*               className="fancybox img-hover-v2"*/}
                                    {/*               title="Image 1">*/}
                                    {/*        <span><img className="img-responsive" th:src="${photo.imageUrl}"*/}
                                    {/*                   alt=""/></span>*/}
                                    {/*            </a>*/}
                                    {/*        </li>*/}

                                    {/*    </ul>*/}
                                    {/*</div>*/}

                                    <div className="row">
                                        <div className="col-md-9 col-sm-8">
                                            <ul className="list-inline posted-info">

                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-sm-4 text-right">
                                            <ul className="article post-shares post-shares-lg ">
                                                <li>
                                                    <ShareButton
                                                        compact
                                                        socialMedia={'facebook'}
                                                        url={`https://klimatskipromeni.mk/article/${article.id}`}
                                                        media={article.imageUrl}
                                                        text={article.name}
                                                    />
                                                </li>
                                                <li>
                                                    <ShareButton
                                                        compact
                                                        socialMedia={'twitter'}
                                                        url={`https://klimatskipromeni.mk/article/${article.id}`}
                                                        media={article.imageUrl}
                                                        text={article.name}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="side col-md-3 mt-3">
                            <div className="article headline-v2 p-1"><h2>{t('article.trending')}</h2>
                            </div>
                            <div style={{"background-color": "white"}}>
                                <ul className="list-unstyled blog-trending mb-5 text-left pl-1 pr-1 pt-2 pb-2">
                                    {recommended}

                                </ul>
                            </div>


                            <div className="article headline-v2 p-1"><h2>{t('article.lastestPosts')}</h2>
                            </div>
                            <div style={{"background-color": "white"}}>
                                <ul className="list-unstyled blog-trending mb-5 text-left pl-1 pr-1 pt-2 pb-2">
                                    {trending}

                                </ul>
                            </div>


                            <div className="article headline-v2 p-1"><h2>{t('article.tags')}</h2></div>
                            <div style={{"background-color": "white"}}>
                                <ul className="list-inline tags-v2 margin-bottom-50 pl-1 pr-1 pt-2 pb-2">
                                    {tagItems}
                                    {/*<li th:each="tag : ${tags}"><a th:href="'/keyword/'+${tag}"*/}
                                    {/*                               th:text="${tag}"></a></li>*/}
                                </ul>
                            </div>
                            {/*<div th:if="${contentPhotoSet.size()>0}">*/}
                            {/*    <div className="article headline-v2"><h2>Photostream</h2></div>*/}
                            {/*    <div className="container-fluid" style="background-color:white">*/}
                            {/*        <ul className="list-inline blog-photostream margin-bottom-50">*/}
                            {/*            <li th:each="photo : ${contentPhotoSet}">*/}
                            {/*                <a th:href="${photo.imageUrl}" data-fancybox="gallery"*/}
                            {/*                   className="fancybox img-hover-v2"*/}
                            {/*                   title="Image 1">*/}
                            {/*                    <span><img className="img-responsive" th:src="${photo.imageUrl}"*/}
                            {/*                               alt=""></span>*/}
                            {/*                </a>*/}
                            {/*            </li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<Subscription/>*/}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Article;