import React, {useContext} from "react";
import {OptionContext} from "../../context/option-context";
import {AudioContext} from "../../context/audio-context";
import {VideoContext} from "../../context/video-context";
import classes from "./options.module.css"
import {MouseHover} from "./MouseHover";

export const getParagraphCssClass = (fontSize, className = "") => {
    if (fontSize === 1) {
        return className && className.includes("small") ? classes.SmallParagraph1 : classes.Paragraph1
    } else if (fontSize === 2) {
        return className && className.includes("small") ? classes.SmallParagraph2 : classes.Paragraph2
    } else if (fontSize === 3) {
        return className && className.includes("small") ? classes.SmallParagraph3 : classes.Paragraph3
    }
    return className && className.includes("small") ? classes.SmallParagraph4 : classes.Paragraph4

}

export const Paragraph = ({children, className}) => {
    const optionsContext = useContext(OptionContext);
    const audioContext = useContext(AudioContext);
    const videoContext = useContext(VideoContext);

    const onClickHandle = (text) => {
        debugger;
        if (optionsContext.sound) {
            audioContext.onChangeAudio(text)
        }
        if (optionsContext.signMark) {
            videoContext.onChangeVideo('frontpage.activities.introduction')
        }
    }

    const paragraphStyle = [className];

    paragraphStyle.push(getParagraphCssClass(optionsContext.fontSize, className))

    if (optionsContext.highlightText) {
        paragraphStyle.push(classes.Highlight)
    }

    return (
        <p onClick={() => onClickHandle(children)} className={paragraphStyle.join(" ")}>
            <MouseHover >{children.toString()}</MouseHover>
        </p>
    )
}