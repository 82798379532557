import react, { Component } from 'react';

class Footer extends Component {


    render() {
        return (
            <div className="footer-v1 sticky-footer">
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p>
                                    2021 &copy; All Rights Reserved.
                                    <a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a>
                                </p>
                            </div>

                            <div className="col-md-6">
                                <ul className="footer-socials list-inline">
                                    <li>
                                        <a href="http://www.thegef.org/gef/" className="tooltips" data-toggle="tooltip"
                                           data-placement="top" title="" data-original-title="Gef">
                                            <img src="/images/gef.png" className="img img-responsive"
                                                 style={{"max-width":"60px"}}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://www.moepp.gov.mk/" className="tooltips" data-toggle="tooltip"
                                           data-placement="top" title="" data-original-title="MZSPP">
                                            <img src="/images/grb.png" className="img img-responsive"
                                                 style={{"max-width":"60px"}}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://www.undp.org.mk/" className="tooltips" data-toggle="tooltip"
                                           data-placement="top" title="" data-original-title="UNDP">
                                            <img src="/images/undp.png" className="img img-responsive"
                                                 style={{"max-width":"60px"}}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;