import axios from "../../config/axios";

const token = "dmlzaXRvcjp2MXMxdDByIQ==";

export const DownloadDocumentRepository = {
    downloadDocument: (id) => {
        return axios.get(`https://dms.klimatskipromeni.mk/proxy/alfresco/api/-default-/public/alfresco/versions/1/nodes/${id}/content`, {
            responseType: 'blob',
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
    }
};