import react from 'react';
import {useTranslation} from "react-i18next";

const Media = (props) => {

    const {t} = useTranslation();

    const items = [{
        href:'http://skopjegallery.b1.finki.ukim.mk',
        color:"rgb(63, 61, 107)",
        text:t('images.stories')
    }, {
        href:'http://unfccc.org.mk/Default.aspx?LCID=231&Control=videos.ascx',
        color:"rgb(36, 120, 58)",
        text:t('media.center.video')
    }, {
        href:'http://ebiblioteka.moepp.gov.mk:8080/library/home',
        color:"rgb(63, 61, 107)",
        text:t('media.center.elibrary')
    }, {
        href:'http://scc.b1.finki.ukim.mk',
        color:"rgb(36, 120, 58)",
        text:t('media.calendar')
    }]

    const tiles =  items.map((item)=>{
        return (
            <a target="_blank" className={"item col-md-3 col-6 mb-3"} href={item.href}>
                <li className="item" style={{"background-color":item.color}}>
                    <div className="owl2-carousel-v2__item">
                        <img className="img-responsive owl2-carousel-v2__item-img"
                             src="/images/empty.png" alt=""/>
                        <div className="owl2-carousel-v2__item-info owl2-carousel-v2__item-info--news">
			  			<span className="owl2-carousel-v2__item-info__item">
					  		<span style={{"font-size":"16px"}}>{item.text}</span>
			  			</span>
                        </div>
                    </div>

                </li>
            </a>
        )
    })

    return (
        <div className={"container-fluid"}>
            <div className="row header-survey" style={{"background-image": "url(/images/heroes/infographics.jpg)"}}>
                <div className="container pt-4">
                    <div className="row g-mb-60 text-center g-heading-v7" style={{"margin-bottom":"20px"}}>
                        <div className="col-8 offset-2">
                            <h2 className="h2">
                        <span className="g-text-height-lg" style={{"text-shadow":"black 0.05em 0.05em 0.05em"}}>
                            {t('media.center')}
                        </span>
                            </h2>
                        </div>
                    </div>
                </div>

            </div>

            <section id="news" className="g-obg-dark-blue1 mt-2 mb-2">
                <div className="container-fluid g-text-height-md g-no-side-padding">
                    <ul className="list-unstyled owl2-carousel-v4 g-no-mb row">

                        {tiles}

                    </ul>
                </div>
            </section>
        </div>
    )
}

export default Media;