import * as React from 'react';
import './news.css'
import {useEffect, useState} from "react";

import {useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Heading} from "../UI/Heading";
import ApiRepository from "../../repository/apiRepository";
import {MouseHover} from "../UI/MouseHover";


const News = (props) => {

    const {t} = useTranslation();

    const [news, setNews] = useState([])

    const {type} = useParams();

    useEffect(()=>{
        if (props.type==="news") {
            ApiRepository.getNews().then((data)=>{
                setNews(data.data);
            })
        } else if (props.type==="blog") {
            ApiRepository.getBlogs().then(data=>{
                setNews(data.data);
            })
        } else if (props.type==="trainings"){
            ApiRepository.getTrainings().then(data=>{
                setNews(data.data);
            })
        }

    },[props.lang])

    const items = news.map(item => {
        return (
            <div className="col-md-3 col-sm-6 col-xs-12 item">
                <div>
                    <a href={item.link}>
                        <div className="owl2-carousel-v2__item" style={{"width": "100%", "height": "250px"}}>
                            <img alt="" className="img-responsive owl2-carousel-v2__item-img"
                                 src={item.img}
                                 style={{"width": "100%", "height": "auto"}}/>
                            <div className="owl2-carousel-v2__item-info owl2-carousel-v2__item-info--news">

                            </div>
                        </div>
                        <div className={"mt-1"} style={{"min-height": "80px"}}>
                            <h5 className="text-clamp news-title"><MouseHover>{item.title}</MouseHover></h5>
                        </div>
                    </a>
                </div>


            </div>
        )
    })

    return (
        <div>
            <div className="header-studies" style={{"background-image": "url(/images/heroes/studies_hero.jpg)"}}>
                <div className="container pt-4">
                    <div className="row g-mb-60 text-center g-heading-v7" style={{"margin-bottom": "20px"}}>
                        <div className="col-sm-8 offset-sm-2">
                            <Heading type={2} className="h2 g-text-height-lg news-header">
                                {t(props.type)}
                            </Heading>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container g-text-height-md " style={{"margin-top": "20px", "margin-bottom": "20px"}}>
                <div className="row">
                    {items}
                </div>
            </div>
        </div>
    )
}

export default News;