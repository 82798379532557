import react, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import './infographics.css'
import Masonry from 'react-masonry-css'
import ApiRepository from "../../repository/apiRepository";

const Infographics = (props) => {

    const {t} = useTranslation();

    const [content,setContent] = useState([])

    useEffect(()=>{
        ApiRepository.getInfographics().then((data)=>{
            setContent(data.data);
        })
    },[props.lang])

    const tiles = content.map((item)=>{
        return (
            <div style={{"background-color":"rgb(36, 120, 58)"}}>
                <div style={{"padding-left":"10px"}} className="owl2-carousel-v2__item">
                    <img className="img-responsive owl2-carousel-v2__item-img" style={{"width":"100%"}}
                         src={item.imageUrl} />
                        <div className="owl2-carousel-v2__item-info owl2-carousel-v2__item-info--news">
                            <div
                                style={{"background-color": "rgba(27, 29, 32, 0.8)","padding-left":"7px", "padding-right":"7px","text-align": "left", "padding": "5px", "padding-bottom":"10px"}}>
                                <a target="_blank" style={{"text-decoration":"none"}} href={item.fileUrl}><span
                                    style={{"text-transform": "uppercase","font-size":"13px","font-weight": "600"}}
                                    >{item.name}</span></a>
                                <br/>
                                <em>{item.description}</em>
                                <a target="_blank"
                                   style={{"text-decoration": "none", "padding":"5px", "font-size":"10px","margin-left": "4px", "background": "#000000"}}
                                   href={item.fileUrl}>DOWNLOAD</a>
                            </div>
                        </div>
                </div>

            </div>
        )
    })

    return (
        <div className={"container-fluid"} style={{"background-color": "#E6ECF0"}}>
            <div className="row header-infographic" style={{"background-image": "url(/images/heroes/infographics.jpg)"}}>
                <div className="container pt-4">
                    <div className="row g-mb-60 text-center g-heading-v7" >
                        <div className="col-8 offset-2">
                            <h2 className="">
                        <span className="g-text-height-lg" style={{"text-shadow": "black 0.05em 0.05em 0.05em"}}>
                            {t('infographics')}
                        </span>
                            </h2>
                        </div>
                    </div>
                </div>

            </div>
            <section>
                <Masonry
                    breakpointCols={3}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {tiles}
                </Masonry>
            </section>
        </div>
    )
}

export default Infographics;