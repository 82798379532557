import {Heading} from "./Heading";
import React from "react";
import {Modal} from "@material-ui/core";
import {Button} from "./Button";


export const ModalUI = ({isOpen, title, body, onClose}) => {
    return (
        <Modal open={isOpen}
               onClose={onClose}
               className={"modal-size"} >
            <div className={"card m-3 my-md-3"}>
                {
                    title ?
                        <div className={"card-header options-card-header"}>
                            <Heading type={2} className={"float-start w-auto text-light"}>{title}</Heading>
                        </div> :
                        <div className={"card-header bg-transparent border-bottom-0"}>
                            <button className={"btn btn-close modal-close-button"} onClick={onClose}/>
                        </div>
                }

                <div className={"card-body"}>
                    {body}
                </div>
                {
                    title ?
                        <div className={"card-footer bg-white border-top-0 text-center"}>
                            <Button className={"btn btn-danger"} onClick={onClose}>Затвори</Button>
                        </div> : null
                }
            </div>
        </Modal>
    )
}