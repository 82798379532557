import React, {useContext, useState} from "react";
import {OptionContext} from "../../context/option-context";
import {AudioContext} from "../../context/audio-context";
import {VideoContext} from "../../context/video-context";
import {useTranslation} from "react-i18next";
import classes from "./options.module.css";
import {getParagraphCssClass} from "./Paragraph";

export const MouseHover = ({children, className, signKey}) => {
    const optionsContext = useContext(OptionContext);
    const audioContext = useContext(AudioContext);
    const videoContext = useContext(VideoContext);
    const {t} = useTranslation();
    const [delayHandler, setDelayHandler] = useState(null)

    const onClickHandle = (text) => {
        if (optionsContext.sound) {
            audioContext.onChangeAudio(text)
        }
        if (optionsContext.signMark) {
            videoContext.onChangeVideo(signKey)
        }
    }

    const handleMouseEnter = (text) => {
        setDelayHandler(setTimeout(() => {
            if (optionsContext.sound) {
                if (text)
                    audioContext.onChangeAudio(text)
                else if (signKey) {
                    audioContext.onChangeAudio(t(signKey))
                }
            }
            if (optionsContext.signMark) {
                videoContext.onChangeVideo(signKey)
            }
        }, 500))
    }



    const handleMouseLeave = () => {
        clearTimeout(delayHandler)
    }

    const render = () => {
        if (children) {
            return (
                children
            )
        }
        if (!children && signKey) {
            return (
                t(signKey)
            )
        }
    }

    const paragraphStyle = [className];

    if (optionsContext.fontSize!=1) {
        paragraphStyle.push(getParagraphCssClass(optionsContext.fontSize, className))
    }


    if (optionsContext.highlightText) {
        paragraphStyle.push(classes.Highlight)
    }

    if (optionsContext.linkMark) {
        paragraphStyle.push(classes.Link)
    }
    else if (optionsContext.highlightText && !optionsContext.linkMark) {
        paragraphStyle.push(classes.Highlight)
    }

    return (
        <span className={paragraphStyle.join(" ")} onMouseEnter={()=>handleMouseEnter(children)} onMouseLeave={()=>handleMouseLeave(children)} onClick={() => onClickHandle(children)}>
            {
                render()
            }

        </span>
    )
}