import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Paragraph} from "../UI/Paragraph";
import {Heading} from "../UI/Heading";
import {MouseHover} from "../UI/MouseHover";

const Contact = (props) => {

    const {t} = useTranslation();

    return (
        <section id="contact" className="g-bg-dark-blue1 contact pt-5">
            <div className="container content-md g-text-height-md">


                <ul className="list-unstyled row text-center">
                    <li className="col-md-3 contact-blocks-v1">
                        <i className="contact-blocks-v1__icon g-display-block g-color-primary icon-globe"></i>
                        <h4>{t('contact.address')}</h4>
                        <MouseHover className="g-display-block g-color-white">{t('contact.address.value')}</MouseHover>
                    </li>
                    <li className="col-md-3 contact-blocks-v1">
                        <i className="contact-blocks-v1__icon g-display-block g-color-primary icon-call-in"></i>
                        <h4>{t('contact.phone')}</h4>
                        <MouseHover className="g-display-block g-color-white">{t('contact.phone.value')}</MouseHover>
                        <MouseHover className="g-display-block g-color-white">{t('contact.phone.value2')}</MouseHover>
                    </li>
                    <li className="col-md-3 contact-blocks-v1">
                        <i className="contact-blocks-v1__icon g-display-block g-color-primary icon-envelope"></i>
                        <h4>{t('contact.email')}</h4>
                        <MouseHover className="g-display-block g-color-white">infoeko@moepp.gov.mk</MouseHover>
                    </li>
                    <li className="col-md-3 contact-blocks-v1">
                        <i className="contact-blocks-v1__icon g-display-block g-color-primary icon-earphones-alt"></i>
                        <h4>{t('contact.site')}</h4>
                        <MouseHover className="g-display-block g-color-white">www.moepp.gov.mk</MouseHover>
                    </li>
                </ul>
            </div>

            <ul className="list-unstyled btn-group btn-group-justified mb-0">
                <li className="btn-group social-block-v1__list">
                    <a href="https://twitter.com/MOEPPMKD" className="btn social-block-v1__list-btn">
                        <i className="fa fa-twitter"></i>
                    </a>
                </li>
                <li className="btn-group social-block-v1__list">
                    <a href="https://www.youtube.com/channel/UCClb47KE53BCaWwXnkz85_Q"
                       className="btn social-block-v1__list-btn">
                        <i className="fa fa-youtube"></i>
                    </a>
                </li>
                <li className="btn-group social-block-v1__list">
                    <a href="https://www.facebook.com/MOEPPMKD/" className="btn social-block-v1__list-btn">
                        <i className="fa fa-facebook"></i>
                    </a>
                </li>
            </ul>
        </section>
    )
}

export default Contact;