import react from 'react';
import {useTranslation} from "react-i18next";
import {MouseHover} from "../UI/MouseHover";
import ApiRepository from "../../repository/apiRepository";

const AskQuestion = (props) => {

    const {t} = useTranslation();

    const sendQuestion = (event) => {
        debugger;
        event.preventDefault();
        ApiRepository.sendQuestion(event.target['email'].value,
            event.target['subject'].value,
            event.target['content'].value).then((data)=>{
                alert("success");
        }).catch((e)=>{
            alert(e);
        })
    }

    return (
        <div className={"bg-color-light"}>
            <div className={"container bg-color-white"}>
                <h2 style={{"font-weight":"200"}} className={"pt-4 pb-4"}>{t('ask.us.climate.question')}</h2>
                <form className={"form"} onSubmit={sendQuestion}>
                    <div className="form-group mt-2">

                        <label className={"pb-2 pt-2"} htmlFor="email"><MouseHover signKey={'content.email'}></MouseHover></label>
                        <input type="email" className="form-control form-control-sm" id="email"
                               aria-describedby="emailHelp"
                               placeholder=""/>
                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="subject" className={"pb-2 pt-2"}><MouseHover signKey={'content.subject'}></MouseHover></label>
                        <input type="text" className="form-control form-control-sm" id="subject"
                               placeholder=""/>
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="content" className={"pb-2 pt-2"}><MouseHover signKey={'content.question'}></MouseHover></label>
                        <textarea className="form-control form-control-sm" rows={5} id="content" placeholder=""/>
                    </div>

                    <button type="submit" className="btn btn-primary btn-sm mt-2 mb-2">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default AskQuestion;