import * as React from 'react';
import {Carousel} from 'react-bootstrap';
import {useEffect, useState} from "react";
import ApiRepository from "../../repository/apiRepository";
import "./slider.css"
import {useTranslation} from "react-i18next";
import {MouseHover} from "../UI/MouseHover";


const Slider = (props) => {

    const [items, setItems] = useState([])

    const {t} = useTranslation();

    useEffect(() => {
        ApiRepository.getSliderItems().then((data) => {
            setItems(data.data);
        })
    }, [props.lang])



    const sliderItems = items.map((item) =>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={item.img}
                alt="First slide"
            />
            <Carousel.Caption>
                <div className={"row"}>
                    <div className={"flex-caption col-md-6 col-sm-12 col-12  text-left"}>
                        <div className={"flex-caption-text"}><MouseHover>{item.title}</MouseHover></div>
                        <a href={item.link} className="btn btn-primary btn-sm">{t("cc.more")}</a>
                    </div>
                </div>
            </Carousel.Caption>
        </Carousel.Item>
    );

    return (
        <Carousel>
            {sliderItems}
        </Carousel>
    )
}

export default Slider;