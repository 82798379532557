import react from 'react';
import {Heading} from "../UI/Heading";
import * as React from "react";
import {useTranslation} from "react-i18next";

const DashboardPage = (props) => {

    const {t} = useTranslation();

    const items = [{
        href:'/research_results/1',
        color:"rgb(63, 61, 107)",
        text:t('policy.makers')
    }, {
        href:t('/research_results/2'),
        color:"rgb(36, 120, 58)",
        text:t('general.public')
    }, {
        href:t('/traffic'),
        color:"rgb(63, 61, 107)",
        text:t('traffic.research')
    }]

    const tiles =  items.map((item)=>{
        return (
            <a target="_blank" className={"item col-md-3 col-6 mb-3"} href={item.href}>
                <li className="item" style={{"background-color":item.color}}>
                    <div className="owl2-carousel-v2__item">
                        <img className="img-responsive owl2-carousel-v2__item-img"
                             src="/images/empty.png" alt=""/>
                        <div className="owl2-carousel-v2__item-info owl2-carousel-v2__item-info--news">
			  			<span className="owl2-carousel-v2__item-info__item">
					  		<span style={{"font-size":"16px"}}>{item.text}</span>
			  			</span>
                        </div>
                    </div>

                </li>
            </a>
        )
    })

    return (
        <>
            <div className="header-survey" style={{"background-image":"url(/images/activities.jpg)"}}>
                <div className="container-fluid pt-6">
                    <div className="row text-center g-heading-v7 mt-4 mb-4">
                        <div className="col-sm-12">
                            <Heading type={2} className="h2 g-text-height-lg ng-binding" style={{"text-shadow": "black 0.05em 0.05em 0.05em;"}}>
                                {t('researches.visualization')}
                            </Heading>
                        </div>
                    </div>
                </div>
            </div>
            <section id="news" className="g-obg-dark-blue1 mt-2 mb-2">
                <div className="container-fluid g-text-height-md g-no-side-padding">
                    <ul className="list-unstyled owl2-carousel-v4 g-no-mb row">

                        {tiles}

                    </ul>
                </div>
            </section>
        </>
    )
}

export default DashboardPage;