import React, {useEffect, useState} from 'react';

export const OptionContext = React.createContext({
    fontSize: 1,
    signMark: false,
    sound: false,
    linkMark: false,
    mousePointer: false,
    highlightText: false,
    textSpacing: false,
    dyslexia: false,
    incrementFontSize: () => {},
    incrementMousePointer: () => {},
    onSignMarkChange: () => {},
    onSoundChange: () => {},
    onLinkMarkChange: () => {},
    onHighlightTextChange: () => {},
    onTextSpacingChange: () => {},
    onDyslexiaChange: () => {}
})

export const OptionContextProvider = props => {
    const [fontSize, setFontSize] = useState(1);
    const [signMark, setSignMark] = useState(false);
    const [sound, setSound] = useState(false);
    const [linkMark, setLinkMark] = useState(false);
    const [mousePointer, setMousePointer] = useState(false);
    const [highlightText, setHighlightText] = useState(false);
    const [textSpacing, setTextSpacing] = useState(false)
    const [dyslexia, setDyslexia] = useState(false)

    useEffect(() => {
        let options = localStorage.getItem("options");
        if (options){
            options = JSON.parse(options);

            setFontSize(options.fontSize || 1);
            setSignMark(options.signMark || false);
            setSound(options.sound || false);
            setLinkMark(options.linkMark || false);
            setMousePointer(options.mousePointer || false);
            setHighlightText(options.highlightText || false);
            setTextSpacing(options.textSpacing || false);
            setDyslexia(options.dyslexia || false);
        }
    }, [])

    useEffect(() => {
        const options = {
            fontSize: fontSize,
            signMark: signMark,
            sound: sound,
            linkMark: linkMark,
            mousePointer: mousePointer,
            highlightText: highlightText,
            textSpacing: textSpacing,
            dyslexia: dyslexia,
        }
        localStorage.setItem("options", JSON.stringify(options));
    }, [fontSize, signMark, sound, linkMark, mousePointer, highlightText, textSpacing, dyslexia])

    const incrementFontSizeHandler = () => {
        setFontSize(prevState => {
            return prevState === 4 ? 1 : prevState + 1;
        })
    }

    return (
        <OptionContext.Provider value={{
            fontSize: fontSize,
            signMark: signMark,
            sound: sound,
            linkMark: linkMark,
            mousePointer: mousePointer,
            highlightText: highlightText,
            textSpacing: textSpacing,
            dyslexia: dyslexia,
            incrementFontSize: incrementFontSizeHandler,
            incrementMousePointer: () => setMousePointer(prevState => {return !prevState}),
            onSignMarkChange: () => setSignMark(prevState => {return !prevState}),
            onSoundChange: () => setSound(prevState => {return !prevState}),
            onLinkMarkChange: () => setLinkMark(prevState => {return !prevState}),
            onHighlightTextChange: () => setHighlightText(prevState => {return !prevState}),
            onTextSpacingChange: () => setTextSpacing(prevState => {return !prevState}),
            onDyslexiaChange: () => setDyslexia(prevState => {return !prevState})
        }}>
            {props.children}
        </OptionContext.Provider>
    )
}