import * as React from 'react';
import './climateActionArticles.css'
import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {Heading} from "../UI/Heading";
import {Paragraph} from "../UI/Paragraph";
import {useTranslation} from "react-i18next";
import ApiRepository from "../../repository/apiRepository";
import {MouseHover} from "../UI/MouseHover";


const ClimateActionArticles = (props) => {
    const {t} = useTranslation();

    const [news,setNews]=useState([])

    useEffect(()=>{
        ApiRepository.getClimateActionArticles(climateactiontype).then((data)=>{
            setNews(data.data);
        })
    },[props.lang])

    const { climateactiontype } = useParams();

    const items = news.map(item=>{
        return (
            <div className="col-md-3 col-sm-6 col-xs-12 item">
                <div>
                    <a href={item.link}>
                        <div className="owl2-carousel-v2__item" style={{"width":"100%","height":"250px"}}>
                            <img alt="" className="img-responsive owl2-carousel-v2__item-img"
                                 src={item.img}
                                 style={{"width":"100%", "height":"auto"}}/>
                                <div className="owl2-carousel-v2__item-info owl2-carousel-v2__item-info--news">

                                </div>
                        </div>
                        <div className={"mt-1"} style={{"min-height":"80px"}}>
                            <h5 className="text-clamp news-title"><MouseHover>{item.title}</MouseHover></h5>
                        </div>
                    </a>
                </div>


            </div>
        )
    })

    return (
        <div>
            <div className="header-studies" style={{"background-image":"url(/images/heroes/studies_hero.jpg)"}} >
                <div className="container pt-4">
                    <div className="row g-mb-60 text-center g-heading-v7" style={{"margin-bottom": "20px"}}>
                        <div className="col-sm-8 offset-sm-2">
                            <Heading type={2} className="h2 g-text-height-lg news-header">
                            {t(`${climateactiontype}`)}
                            </Heading>
                        </div>
                    </div>
                </div>

            </div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 pull-right">
                            <a className="btn btn-sm btn-primary pull-right btn-add" href={`/blog/create/${climateactiontype}`}
                               target="_blank">
                                <i className="fa fa-plus"></i>

                                <span style={{"text-shadow": "black 0.05em 0.05em 0.05em"}}>
                                    <MouseHover signKey={'add.climate.action'}/>
                        </span>
                            </a>

                        </div>
                    </div>
                </div>
            </section>
            <div className="container g-text-height-md " style={{"margin-top":"20px","margin-bottom":"20px"}}>
                <div className="row">
                    {items}
                </div>
            </div>
        </div>
    )
}

export default ClimateActionArticles;