import './App.css';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';
import TopNav from './components/TopNav/topnav';

import Contact from "./components/Contact/contact";
import {Suspense, useContext, useEffect, useState} from 'react'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Article from "./components/Article/article";
import FrontPage from "./components/FrontPage/frontPage";
import News from "./components/News/news";
import ClimateActions from "./components/ClimateActions/climateActions";
import ClimateActionArticles from "./components/ClimateActionArticles/climateActionArticles";
import ResearchPage from "./components/Research/researchPage";
import i18n from './config/i18next';
import {Options} from "./components/Options/Options";

import classes from "./components/UI/options.module.css";
import {OptionContext} from "./context/option-context";
import {AudioContext} from "./context/audio-context";
import {Video} from "./components/Video/Video";
import AskQuestion from "./components/AskQuestion/askQuestion";
import {ToastContainer} from "react-toastify";
import IndicatorFallback from "./components/IndicatorFallback";
import Infographics from "./components/Infographics/infographics";
import Surveys from "./components/Surveys/surveys";
import Media from "./components/Media/media";
import ContactPage from "./components/ContactPage/contactPage";
import DashboardPage from "./components/DashboardPage/dashboardPage";
import StudiesPage from "./components/StudiesPage/studiesPage";
import {GiSoundWaves} from "react-icons/gi";
import {Heading} from "./components/UI/Heading";
import {Paragraph} from "./components/UI/Paragraph";
import {MouseHover} from "./components/UI/MouseHover";
import {ModalUI} from "./components/UI/Modal";
import PdfDocumentViewer from "./components/PdfDocumentViewer/PdfDocumentViewer";





function App() {
    const optionsContext = useContext(OptionContext);
    const audioContext = useContext(AudioContext);
    const [lang,setLang] = useState({})
    const [showModal, setShowModal] = useState({
        open: false,
        title: '',
        children: <></>
    })

    const onLanguageChange = (value) => {
        i18n.changeLanguage(value)
        localStorage.setItem('locale',value)
        setLang(value);
    }

    useEffect(()=>{
        if (!localStorage.getItem("locale")) {
            localStorage.setItem('locale','mk');
        }
        let options = localStorage.getItem("options");
        if (options) {
            options = JSON.parse(options);
            if (options.sound) {
                onOpenModal("", <div className={"text-center"}>
                    <GiSoundWaves size={100} className="m-2"/>
                    <h3>Предупредување!</h3>
                    <p>Имате вклучено говорен асистент</p>
                    <div className={"btn-group"}>
                        <button className={"btn btn-danger"} onClick={onCancelAudio}>Откажи</button>
                        <button className={"btn btn-success"} onClick={onCloseModal}>Прифати</button>
                    </div>
                </div>)
            }
        }
    },[])

    const onOpenModal = (title, children) =>
    {
        setShowModal({
            open: true,
            title: title,
            children: children
        })
    }

    const onCancelAudio = () =>
    {
        optionsContext.onSoundChange();
        onCloseModal();
    }

    const onCloseModal = () => setShowModal({open: false, title: "", children:<></> })

    const bodyClasses = [];
    if (optionsContext.mousePointer){
        bodyClasses.push(classes.IncrementMouse)
    }
    if (optionsContext.textSpacing){
        bodyClasses.push(classes.TextSpacing)
    }
    if (optionsContext.dyslexia) {
        bodyClasses.push(classes.DyslexiaFont);
    }

    if (audioContext.loading) {
        bodyClasses.push(classes.Loading);
    }

    return (
        <Suspense fallback={<IndicatorFallback/>}>
            <div className={bodyClasses.join(" ")}>
                <TopNav changeLanguage={onLanguageChange}/>
                <Header/>

                <Router>
                    <Routes>

                        <Route path={"/"} exact element={<FrontPage lang={lang} showModal={onOpenModal} />}/>
                        <Route path={"/article/:articleId"} exact element={<Article lang={lang}/>}/>
                        <Route path={"/news"} type={"news"} exact element={<News type={"news"}/>}/>
                        <Route path={"/activities"} exact element={<ClimateActions/>}/>
                        <Route path={"/researches"} exact element={<ResearchPage/>}/>
                        <Route path={"/climate_actions/:climateactiontype"} exact element={<ClimateActionArticles/>}/>
                        <Route path={"/blog"} exact element={<News type={"blog"}/>}/>
                        <Route path={"/trainings"} exact element={<News type={"trainings"}/>}/>
                        <Route path={"/askquestion"} exact element={<AskQuestion/>}/>
                        <Route path={"/infographics"} exact element={<Infographics lang={lang}/>}/>
                        <Route path={"/surveys"} exact element={<Surveys lang={lang}/>}/>
                        <Route path={"/media_center"} exact element={<Media lang={lang}/>}/>
                        <Route path={"/contact"} exact element={<ContactPage lang={lang}/>}/>
                        <Route path={"/dashboard"} exact element={<DashboardPage lang={lang}/>}/>
                        <Route path={"/studies"} exact element={<StudiesPage lang={lang}/>}/>

                        {/*<Route path={"/documentPreview/:id"} exact element={<PdfDocumentViewer/>}/>*/}
                    </Routes>
                </Router>
                <Contact/>
                <Options/>
                <Video/>
                <Footer/>
                <ModalUI isOpen={showModal.open}
                         title={showModal.title}
                         body={showModal.children}
                         onClose={onCloseModal}/>
            </div>
            <ToastContainer className={"m-3"}/>
        </Suspense>
    );
}

export default App;
