import axios from "../config/axios";

export const ApiRepository = {
    getSliderItems: () => {
        return axios.get(`/data/rest/api/slider`);
    },
    getLatestNews: () => {
        return axios.get(`/data/rest/api/latestNews`);
    },
    getProjects: () => {
        return axios.get(`/data/rest/api/projects`);
    },
    getTiles: () => {
        return axios.get(`/data/rest/api/tiles`);
    },
    getArticle: (id) => {
        return axios.get(`/data/rest/api/item/${id}`);
    },
    getNews: () => {
        return axios.get(`/data/rest/api/news`);
    },
    getClimateActionArticles: (type) => {
        return axios.get(`/data/rest/api/climate_actions/${type}`)
    },
    getBlogs: () => {
        return axios.get(`/data/rest/api/blogs`);
    },
    getTrainings: () => {
        return axios.get(`/data/rest/api/trainings`);
    },
    getSignByLangCode: (lang) => {
        return axios.get(`/data/rest/translation/sign_language/${lang}`)
    },
    sendQuestion: (email,subject,question) => {
        let entity = {
            email:email,
            subject:subject,
            question:question
        }
        return axios.post("/data/rest/question",entity)
    },

    getInfographics: () => {
        return axios.get("/data/rest/api/infographics");
    },

    searchTerm: (term) => {
        return axios.get("/data/rest/api/search?term="+term);
    }
}

export default ApiRepository;