import * as React from 'react'
import "./topnav.css";
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import i18n from '../../config/i18next';
import {MouseHover} from "../UI/MouseHover";

const TopNav = (props) => {

    const {t} = useTranslation();

    return (
        <div className="top-bar">
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {/*<Nav.Link className="top-bar-item" href="#home"><MouseHover signKey={'top.menu.about_us'}></MouseHover></Nav.Link>*/}
                            <Nav.Link className="top-bar-item" href="/media_center"><MouseHover signKey={'top.media.links'}></MouseHover></Nav.Link>
                            <Nav.Link className="top-bar-item" href="/article/35"><MouseHover signKey={'cc.links'}></MouseHover></Nav.Link>
                            <Nav.Link className="top-bar-item" href="/contact"><MouseHover signKey={'top.menu.contact'}></MouseHover></Nav.Link>
                            <Nav.Link className="top-bar-item" href="http://unfccc.org.mk/"><MouseHover signKey={'top.menu.archive'}></MouseHover></Nav.Link>
                            <Nav.Link className="top-bar-item" href="https://dms.klimatskipromeni.mk/public/#/login?redirectUrl=%2Flibraries"><MouseHover signKey={'cc.knowledge_management'}></MouseHover></Nav.Link>
                        </Nav>
                        <Nav className={"ms-auto"}>
                            <Nav.Link href="" onClick={()=>props.changeLanguage("mk")}>МК</Nav.Link>
                            <Nav.Link href="" onClick={()=>props.changeLanguage("en")}>EN</Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


        </div>
    )
}

export default TopNav;