import react from 'react';
import * as React from "react";
import {useTranslation, initReactI18next} from "react-i18next";
import {Heading} from "../UI/Heading";
import {Paragraph} from "../UI/Paragraph";
import {MouseHover} from "../UI/MouseHover";

const ResearchPage = (props) => {

    const {t} = useTranslation();

    const researchTypes = [{
        "title": "researches.micronarratives",
        "color": "rgb(36, 120, 58)",
        "link": "researches.micronarratives.link"
    }, {
        "title": "researches.visualization",
        "color": "rgb(62, 120, 179)",
        "link": "/dashboard"
    }, {
        "title": "researches.survey",
        "color": "rgb(63, 61, 107)",
        "link": "/surveys"
    }, {
        "title": "researches.studies",
        "color": "rgb(21, 84, 81)",
        "link": "/studies"
    }, {
        "title": "researches.papers",
        "color": "rgb(62, 120, 179)",
        "link": "http://unfccc.org.mk/Default.aspx?LCID=286"
    }, {
        "title": "twitter.research",
        "color": "rgb(21, 84, 81)",
        "link": "/tags"
    }]

    const items = researchTypes.map(item => {
        return (
            <a className="item col-md-2 col-6 mb-3 col-6" href={t(item.link)}>
                <li style={{"background-color": item.color}}>
                    <div className="owl2-carousel-v2__item">
                        <img className="img-fluid owl2-carousel-v2__item-img"
                             src="/images/empty.png" alt=""/>
                        <div className="owl2-carousel-v2__item-info owl2-carousel-v2__item-info--news">
                            <span className="owl2-carousel-v2__item-info__item">
                                <MouseHover>{t(item.title)}</MouseHover>
                            </span>
                        </div>
                    </div>
                </li>
            </a>
        )
    })

    return (
        <>
            <div className="header-survey" style={{"background-image": "url(/images/activities.jpg)"}}>
                <div className="container-fluid pt-6">
                    <div className="row text-center g-heading-v7 mt-4 mb-4">
                        <div className="col-sm-12">
                            <Heading type={2} className="h2 g-text-height-lg ng-binding"
                                     style={{"text-shadow": "black 0.05em 0.05em 0.05em;"}}>
                                {t('cc.dashboard')}
                            </Heading>
                        </div>
                    </div>
                </div>
            </div>
            <section id="news" className="mt-3" style={{"margin-top": "20px;margin-bottom:20px"}}>
                <div className="container">
                    <div>
                        <ul className="list-unstyled owl2-carousel-v4 g-no-mb row">
                            {items}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResearchPage;