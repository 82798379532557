import * as React from 'react'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {Paragraph} from "../UI/Paragraph";
import {MouseHover} from "../UI/MouseHover";
import "./header.css";


const Header = () => {
    const {t} = useTranslation();

    return (
            <div className="header">
                <Navbar bg="" expand="lg">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                src="/logo.png"
                                width="50"
                                height="50"
                                className="d-inline-block align-top"
                                alt="Климатски Промени Лого"
                            />{' '}
                            <span className="brand-name"><MouseHover signKey={'cc.climate'}/> <MouseHover signKey={'cc.climate'}>{t('cc.changes')}</MouseHover></span>


                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">

                            </Nav>
                            <Nav>
                                <Nav.Link className="header-item" href="/"><MouseHover signKey={'cc.home'}></MouseHover></Nav.Link>
                                <Nav.Link className="header-item" href="/news"><MouseHover signKey={'cc.news'}></MouseHover></Nav.Link>
                                <Nav.Link className="header-item" href="/activities"><MouseHover signKey={'cc.activities'}></MouseHover></Nav.Link>
                                <Nav.Link className="header-item" href="/researches"><MouseHover signKey={'cc.dashboard'}></MouseHover></Nav.Link>
                                <Nav.Link className="header-item" href="/trainings"><MouseHover signKey={'cc.trainings'}></MouseHover></Nav.Link>
                                <Nav.Link className="header-item" href="/blog"><MouseHover signKey={'cc.blog'}></MouseHover></Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>


            </div>
    )
}

export default Header;