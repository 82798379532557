import * as React from 'react';
import './climateActions.css'
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Heading} from "../UI/Heading";
import {Paragraph} from "../UI/Paragraph";


const ClimateActions = () => {

    const {t} = useTranslation();

    const climateActionsTypes = [{
        "title": "activities.municipalities",
        "color": "rgb(36, 120, 58)",
        "link": "/climate_actions/CONTENT_CLIMATE_ACTION_MUNICIPALITIES"
    }, {
        "title": "activities.business",
        "color": "rgb(63, 61, 107)",
        "link": "/climate_actions/CONTENT_CLIMATE_ACTION_BUSINESS"
    }, {
        "title": "activities.education",
        "color": "rgb(21, 84, 81)",
        "link": "/climate_actions/CONTENT_CLIMATE_ACTION_EDUCATION"
    }, {
        "title": "activities.organizations",
        "color": "rgb(62, 120, 179)",
        "link": "/climate_actions/CONTENT_CLIMATE_ACTION_NON_GOVERNMENTAL"
    }, {
        "title": "activities.households",
        "color": "rgb(21, 84, 81)",
        "link": "/climate_actions/CONTENT_CLIMATE_ACTION_HOUSEHOLDS"
    }]

    const items = climateActionsTypes.map(item => {
        return (
            <a className="item col-md-2 col-6 mb-3 col-6" href={item.link} >
                <li style={{"background-color": item.color}}>
                    <div className="owl2-carousel-v2__item">
                        <img className="img-fluid owl2-carousel-v2__item-img"
                             src="/images/empty.png" alt=""/>
                        <div className="owl2-carousel-v2__item-info owl2-carousel-v2__item-info--news">
                            <span className="owl2-carousel-v2__item-info__item">
                                <span>{t(item.title)}</span>
                            </span>
                        </div>
                    </div>
                </li>
            </a>
        )
    })

    return (
        <>
            <div className="header-survey" style={{"background-image":"url(/images/activities.jpg)"}}>
                <div className="container-fluid pt-6">
                    <div className="row text-center g-heading-v7 mt-4 mb-4">
                        <div className="col-sm-12">
                            <Heading type={2} className="h2 g-text-height-lg ng-binding" style={{"text-shadow": "black 0.05em 0.05em 0.05em;"}}>
                                #КлиматскаAкција
                            </Heading>
                        </div>
                    </div>
                </div>
            </div>
            <section id="news" className="mt-3" style={{"margin-top":"20px;margin-bottom:20px"}}>
                <div className="container">
                    <div>
                        <ul className="list-unstyled owl2-carousel-v4 g-no-mb row">
                            <div className={"col-md-1 col-xs-12 col-12"}></div>
                            {items}
                            <div className={"col-md-1 col-xs-12 col-12"}></div>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )

}

export default ClimateActions;