import * as React from 'react';
import './latestNews.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FaSearch} from "react-icons/fa";
import {faBullhorn} from '@fortawesome/free-solid-svg-icons'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {useTranslation} from "react-i18next";
import ApiRepository from "../../repository/apiRepository";
import {useEffect, useState} from "react";
import {MouseHover} from "../UI/MouseHover";
import TilesCarousel from "./tilesCarousel";
import {SearchRepository} from "./SearchRepository";
import {SearchModal} from "./SearchModal";
import {toast} from "react-toastify";

const LatestNews = (props) => {

    const {t} = useTranslation();

    const [news,setNews]=useState([])
    const [projects,setProjects]=useState([])
    const [tiles,setTiles]=useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [showSearchModal, setShowSearchModal] = useState(false)


    const responsiveOptionsProjects = {
        0: {
            items: 2
        },
        600: {
            items: 2
        },
        1000: {
            items: 3
        }
    }
    const init = () => {
        const latestNews = ApiRepository.getLatestNews().then((data)=>{
            setNews(data.data);
        })

        const latestProjects = ApiRepository.getProjects().then((data)=>{
            setProjects(data.data)
        });

        const tiles = ApiRepository.getTiles().then((data)=>{
            setTiles(data.data);
        })
    }

    useEffect(()=>{
        init();
    },[props.lang])

    const searchHandle = (event) => {
        event.preventDefault();
        setShowSearchModal(true);
    }


    const newsItems = () => {
        return news.map(item=>{
            return (
                <div className={"col-md-3 col-sm-6 col-6 col-xl-3"}>
                    <a href={item.link}>
                        <div>
                            <img className={"img-fluid"}
                                 src={item.img}/>
                        </div>
                        <div>
                            <MouseHover className={"title text-clamp"}>{item.title}</MouseHover>
                        </div>
                    </a>

                </div>
            )
        })
    }

    const projectItems = projects.map((item)=>{
        return (
            <div className="item col-md-4 col-sm-6 col-xs-12">

                <div className="owl2-carousel-v2__item project-item">
                    <a href={`/article/${item.id}`}>
                        <div className="owl2-carousel-v2__item-info bg-blue">
                            <img alt="" className="img-fluid owl2-carousel-v2__item-img"
                                 src={"./images/bover.png"}/>
                            <MouseHover className={"absolute-pos"}>{item.title}</MouseHover>
                        </div>
                    </a>
                </div>
            </div>
        )
    })

    // const tilesContent = () => {
    //     let response = tiles.map((item)=>{
    //         return (
    //             <div className='item'>
    //                 <div>
    //                     <a href={item[0].link}>
    //                         <div className={"custom-owl-item "}>
    //                             <div className={"item-img"} style={{"backgroundImage": `url(${item[0].img})`}}></div>
    //                             <MouseHover className={"owl-text text-clamp text-left project-text"} style={{"vertical-align": "bottom"}}>{item[0].title}</MouseHover>
    //                         </div>
    //                     </a>
    //                 </div>
    //
    //                 { item[1] &&
    //                 <div className={"mt-2"}>
    //                     <a href={item[1].link} className={"mt-2"}>
    //                         <div className={"custom-owl-item "}>
    //                             <div className={"item-img"} style={{"backgroundImage": `url(${item[1].img})`}}></div>
    //                             <MouseHover className={"owl-text text-clamp text-left project-text"} style={{"vertical-align": "bottom"}}>{item[1].title}</MouseHover>
    //                         </div>
    //                     </a>
    //                 </div>
    //                 }
    //
    //             </div>
    //         )
    //     })
    //     return response;
    // }

    return (
        <div className={"mt-4"}>
            <h3 className={"g-text-height-lg h-light text-center"}>
                <MouseHover signKey={'lastest.news'}/>
            </h3>
            <div className={"row"}>
                <div className={"col-md-3 col-sm-6 col-6 col-xl-3"}>
                    <a href="/askquestion">
                        <div>
                            <img className={"img-fluid"}
                                 src="/images/ask_question.jpg"/>
                        </div>
                        <div>
                            <span className={"title text-clamp"}><MouseHover signKey={'ask.us.climate.question'}></MouseHover></span>
                        </div>
                    </a>

                </div>
                {
                    newsItems()
                }

            </div>

            <div className={"row mt-5"}>
                <div className="col-sm-12">
                    <a className="btn btn-lg btn-success btn-block upper-text left-icon-holder prijavi-ka left-icon-holder"
                       href="/activities">
                        <FontAwesomeIcon color="white" className={"fa"} icon={faBullhorn}/>
                        <span className="box-text"><MouseHover signKey={'add.climate.action'}>{t('add.climate.action')}</MouseHover></span>
                    </a>
                </div>
            </div>
            <div className={"row mt-5"}>
                <div className={"col-sm-12 text-center p-3 mt-2"}>
                    <h3>{t('search.text')}</h3>
                </div>
                <div className={"col-sm-12 col-md-6 mx-auto p-2"}>
                    <form onSubmit={searchHandle}>
                        <div className={"input-group"}>
                            <button type={"submit"} className={"btn input-group-text border rounded-start border-end-0"}>
                                <FaSearch/>
                            </button>
                            <input
                                type={"text"}
                                value={searchTerm}
                                onChange={(event) => setSearchTerm(event.target.value)}
                                placeholder={t('search.findterm')}
                                className={"form-control border-start-0"} />
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row mt-5"}>
                <h3 className={"g-text-height-lg h-light text-center"}>
                   <MouseHover signKey={'frontpage.activities.introduction'}></MouseHover>
                </h3>

                <TilesCarousel tiles={tiles} lang={props.lang}/>
            </div>

            <div className={"row mt-5"}>
                <div className={"col-md-3"}>
                    <div className="g-mb-60 pt-3">
                        <h2 className="h2" style={{"line-height": 1,"text-align":"left"}}>
                            <span className="g-text-height-md ng-binding"
                                  style={{"font-size":"20px"}}><MouseHover signKey={'frontpage.projects.title1'}></MouseHover> <br/> <MouseHover signKey={'frontpage.projects.title2'}></MouseHover></span>
                        </h2>
                    </div>
                </div>
                <div className={"col-md-9"}>
                    <div className={"row"}>
                        {projectItems}
                    </div>
                </div>
            </div>
            {showSearchModal &&
                <SearchModal
                    isOpen={showSearchModal}
                    onClose={() => setShowSearchModal(false)}
                    searchTerm={searchTerm}/>
            }
        </div>
    )
}

export default LatestNews;