import react, {useEffect} from 'react';
import OwlCarousel from "react-owl-carousel";
import * as React from "react";
import {MouseHover} from "../UI/MouseHover";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TilesCarousel = (props) => {


    const responsiveOptions = {
        0: {
            items: 2
        },
        600: {
            items: 2
        },
        1000: {
            items: 4
        }
    }

    useEffect(()=>{

    },[props])



    return (
        <>
        { props.tiles.length > 0 && (
            <OwlCarousel className='owl-theme' loop margin={10} items={4} responsive={responsiveOptions} nav key={`carousel_${props.lang}_${props.tiles.length}`}>
                {
                    props.tiles.map((item)=>{
                        return (
                            <div className='item' key={item[0].id}>
                                <div>
                                    <a href={item[0].link}>
                                        <div className={"custom-owl-item "}>
                                            <div className={"item-img"} style={{"backgroundImage": `url(${item[0].img})`}}></div>
                                            <MouseHover className={"owl-text text-clamp text-left project-text"} style={{"vertical-align": "bottom"}}>{item[0].title}</MouseHover>
                                        </div>
                                    </a>
                                </div>

                                { item[1] &&
                                <div className={"mt-2"}>
                                    <a href={item[1].link} className={"mt-2"}>
                                        <div className={"custom-owl-item "}>
                                            <div className={"item-img"} style={{"backgroundImage": `url(${item[1].img})`}}></div>
                                            <MouseHover className={"owl-text text-clamp text-left project-text"} style={{"vertical-align": "bottom"}}>{item[1].title}</MouseHover>
                                        </div>
                                    </a>
                                </div>
                                }

                            </div>
                        )
                    })
                }

            </OwlCarousel>
            )
        }
        </>
    )
}

export default TilesCarousel;