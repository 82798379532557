import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-design-icons/iconfont/material-icons.css';
import $ from 'jquery'
import "simple-line-icons";
import 'font-awesome/css/font-awesome.min.css';

import {VideoContextProvider} from "./context/video-context";
import {AudioContextProvider} from "./context/audio-context";
import {OptionContextProvider} from "./context/option-context";
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
      <OptionContextProvider>
          <VideoContextProvider>
              <AudioContextProvider>
                  <App />
              </AudioContextProvider>
          </VideoContextProvider>
      </OptionContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
