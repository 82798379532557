import axios from 'axios';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_GATEWAY}`,
    headers: {
        'Access-Control-Allow-Origin': '*'
    },
});

instance.interceptors.request.use(
    config => {
        const locale = localStorage.getItem("locale") || "mk";
        if (locale) config.headers['locale'] = `${locale}`;
        return config;
    },
    error => {
        console.log(error.response.data);
    }
);

export default instance;