import React, {useEffect, useState} from 'react';

export const AudioContext = React.createContext({
    text: null,
    audio: null,
    isPlaying: false,
    loading: false,
    onChangeAudio: (value) => {
    },
    onPause: () => {
    },
    onPlay: () => {
    },
    onClose: () => {
    }
})

export const AudioContextProvider = props => {
    const [text, setText] = useState(null);
    const [audio, setAudio] = useState(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        isPlaying ? audio.play() : audio.pause()

        if (isPlaying) {
            audio.oncanplay = () => setLoading(false)
            audio.onended = () => {
                audio.currentTime = 0;
                setIsPlaying(false)
            }
        }
    }, [isPlaying])

    const onTextChange = (value) => {
        if (value) {
            setText(value);
            // setAudio(new Audio(`https://tts.ireason.mk/predict?text=${value}&locale=${locale ? locale : "mk"}`));
            const locale = localStorage.getItem("locale");
            setLoading(true);
            if (locale === "mk") {
                setAudio(new Audio(`https://tts.ireason.mk/predict?text=${value}&locale=${locale ? locale : "mk"}`));
            } else {
                setAudio(new Audio(`https://tts-en.ireason.mk/predict?text=${value}&locale=${locale ? locale : "en"}`));
            }
            setIsPlaying(true)
        } else {
            onCloseHandle();
        }
    }

    const onPlay = () => {
        setIsPlaying(true);
    }

    const onPause = () => {
        setIsPlaying(false);
    }

    const onCloseHandle = () => {
        setText(null);
        setAudio(null);
        setIsPlaying(false);
    }

    return (
        <AudioContext.Provider value={{
            text: text,
            audio: audio,
            isPlaying: isPlaying,
            loading: loading,
            onChangeAudio: onTextChange,
            onPause: onPause,
            onPlay: onPlay,
            onClose: onCloseHandle
        }}>
            {props.children}
        </AudioContext.Provider>
    )
}