import React, {useEffect, useState} from "react";
import {Modal} from "@material-ui/core";
import * as SiIcons from "react-icons/si";
import * as VscIcons from "react-icons/vsc";
import {DownloadDocumentRepository} from "./DownloadDocumentRepository";
import ModalHeader from "react-bootstrap/ModalHeader";
import {ModalBody} from "react-bootstrap";
import axios from "axios";
import {SearchRepository} from "./SearchRepository";
import {toast} from "react-toastify";
import {FaSearch} from "react-icons/fa";
import {t} from "i18next";
import moment from "moment";

const mimeTypes = {
    "PDF": "application/pdf",
    "WORD": "application/msword",
    "HTML": "text/html"
}

export const SearchModal = ({isOpen, onClose, searchTerm}) => {

    const [term, setTerm] = useState(searchTerm)
    const [inputTerm, setInputTerm] = useState(searchTerm)
    const [searchResultsDMS, setSearchResultsDMS] = useState([]);
    const [searchResultsWeb, setSearchResultsWeb] = useState([]);
    const [isWebResultsLoading, setIsWebResultsLoading] = useState(false);
    const [isDmsResultsLoading, setIsDmsResultsLoading] = useState(false);


    useEffect(() => {
        debugger;
        setIsWebResultsLoading(true);
        setIsDmsResultsLoading(true);
        SearchRepository.searchTermInDms(term)
            .then(response => {
                const entries = response.data.list.entries;
                setSearchResultsDMS(entries);
                if (entries.length === 0) {
                    toast.info("Не успеавме да најдеме податоци од пребарувањето")
                }
                setIsDmsResultsLoading(false);
            })
            .catch(error => {
                console.log(error);
            })

        SearchRepository.searchTermInWeb(term)
            .then(response => {
                const entries = response.data;
                setSearchResultsWeb(entries);
                setIsWebResultsLoading(false);
            })
    }, [term])

    const searchHandle = (e) => {
        e.preventDefault();
        debugger;
        clearSearchResults();
        setTerm(e.target["inputTerm"].value)
    }

    const clearSearchResults = () => {
        setSearchResultsWeb([]);
        setSearchResultsDMS([]);
    }

    const downloadDocument = (id, name) => {
        if (id) {
            DownloadDocumentRepository
                .downloadDocument(id)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: "pdf"}));
                    const link = document.createElement('a');

                    link.href = url;
                    link.target = "_blank";
                    link.download = name

                    document.body.appendChild(link);
                    link.click();
                })
        }
    }

    const downloadPdf = (url) => {
        try {
            axios
                .get(url, {
                    responseType: "blob"

                })
                .then((response) => {
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {type: "application/pdf"});
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    const pdfWindow = window.open();
                    pdfWindow.location.href = fileURL;
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            return {error};
        }
    }

    const mapEntriesDMS = () => {
        if (searchResultsDMS.length == 0) {
            return (
                <div>Не постои документ со дадениот клучен збор.</div>
            )
        }
        return searchResultsDMS.map(entry => {
            return <div key={entry.entry.id} className={"col-12 border-bottom p-2"}>
                <div className={"row"}>
                    {
                        entry.entry.content && entry.entry.content.mimeType === mimeTypes.PDF ?
                            <span className={"col-1 text-center"}><VscIcons.VscFilePdf size={30}
                                                                                       style={{color: "red"}}/> </span> : null
                    }
                    {
                        entry.entry.content && entry.entry.content.mimeType === mimeTypes.WORD ?
                            <span className={"col-1 text-center"}><SiIcons.SiMicrosoftword size={30}
                                                                                           style={{color: "blue"}}/> </span> : null
                    }
                    {
                        entry.entry.content && entry.entry.content.mimeType === mimeTypes.HTML ?
                            <span className={"col-1 text-center"}><SiIcons.SiHtml5 size={30} style={{color: "orange"}}/> </span> : null
                    }
                    <div className={"col"}>
                        {
                            entry.entry.content && entry.entry.content.mimeType === mimeTypes.PDF ?
                                <p className={'text-primary text-decoration-underline search-download'}
                                   onClick={() => downloadPdf(`https://dms.klimatskipromeni.mk/proxy/alfresco/api/-default-/public/alfresco/versions/1/nodes/${entry.entry.id}/content`)}>{entry.entry.name}</p>
                                : null
                        }
                        {
                            entry.entry.content && entry.entry.content.mimeType !== mimeTypes.PDF ?
                                <p className={'text-primary text-decoration-underline search-download'}
                                   onClick={() => downloadDocument(`https://dms.klimatskipromeni.mk/proxy/alfresco/api/-default-/public/alfresco/versions/1/nodes/${entry.entry.id}/content`, entry.entry.name)}>{entry.entry.name}</p>
                                : null
                        }
                    </div>
                </div>

            </div>
        })
    }

    const mapEntriesWeb = () => {
        if (searchResultsWeb.length == 0) {
            return (
                <div>Не постои веб содржина со бараниот клучен збор.</div>
            )
        }
        return searchResultsWeb.map(entry => {
            return <div key={entry.id} className={"col-12 border-bottom p-2"}>
                <div className={"row"}>
                    <>
                        {
                            entry.name ?
                                <span className={"col-1 text-center"}><SiIcons.SiHtml5 size={30}
                                                                                       style={{color: "orange"}}/> </span> : null
                        }
                        <div className={"col"}>
                            {
                                <>
                                <a target={"_blank"} href={entry.link}>{entry.name}</a><br/>
                                <span>{moment(new Date(entry.datePublished.millis)).format("DD/MM/YYYY")}</span>
                                </>
                            }
                        </div>
                    </>
                </div>

            </div>
        })
    }

    return (
        <Modal open={isOpen}
               onClose={onClose}
               fullScreen={true}
        >
            <div>
                <div className={"container-fluid"}>
                    <div className={"text-center card-header bg-green"}>
                        <h3>{t('search.results')}: <b>{term}</b></h3>
                        <button className={"btn btn-close pull-right "} style={{"marginTop": "-30px"}}
                                onClick={onClose}/>
                    </div>
                    <div className={"bg-white card"}>
                        <div className={"row card-body"}>
                            <div className={"col-12 mb-4"}>
                                <form onSubmit={searchHandle}>
                                    <div className={"input-group"}>
                                        <button type={"submit"}
                                                className={"btn input-group-text border rounded-start border-end-0"}>
                                            <FaSearch/>
                                        </button>
                                        <input
                                            type={"text"}
                                            value={inputTerm}
                                            name={"inputTerm"}
                                            onChange={(event) => setInputTerm(event.target.value)}
                                            placeholder={t('search.findterm')}
                                            className={"form-control border-start-0"}/>
                                    </div>
                                </form>
                            </div>
                            <div class={"col-6"}>
                                <h4>{t('search.resultsweb')}: ({searchResultsWeb.length} {t('results')})</h4>
                                <hr/>
                                { isWebResultsLoading &&
                                    <div className="spinner-border text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                                <div style={{maxHeight: "calc(100vh - 200px)", overflowY: "auto"}}>
                                    {mapEntriesWeb()}
                                </div>
                            </div>

                            <div className={"col-6"}>
                                <h4>{t('search.resultsdms')}: ({searchResultsDMS.length} {t('results')})</h4>
                                <hr/>
                                { isDmsResultsLoading &&
                                    <div className="spinner-border text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                                <div style={{maxHeight: "calc(100vh - 200px)", overflowY: "auto"}}>
                                    {mapEntriesDMS()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}