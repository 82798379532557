import React, {useEffect, useState} from "react";
import {OptionsMenu} from "./OptionsMenu";
import {FaUniversalAccess} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import "./options.css"

export const Options = props => {
    const [openOptions, setOpenOptions] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const {t} = useTranslation();

    // useEffect(() => {
    //     setTimeout(() =>{
    //         setShowMessage(false)
    //     }, 3000)
    // }, [])

    return (
        <div style={{position: "fixed", bottom: "20px", right: "20px", width: "auto", zIndex: 1}}>
            {
                openOptions && <OptionsMenu isOpen={openOptions} onClose={() => setOpenOptions(false)} />
            }

            <div className={"popup float-end"}>
                <FaUniversalAccess
                    className={"float-end"}
                    onClick={() => setOpenOptions(!openOptions)}
                    title={t("Постави поставки")}
                    id={"options-button"} />
                {
                    // showMessage ? <span className={`popuptext show`} id="myPopup">{t("Кликни ме!")}</span> : null
                }


                {/*<img width={50}*/}
                {/*     onClick={() => setOpenOptions(!openOptions)}*/}
                {/*     className={"float-end rounded-circle"}*/}
                {/*     title={"Постави поставки"}*/}
                {/*     id={"options-button"}*/}
                {/*     src="accessibility.png"*/}
                {/*     alt={"Accessibility-MK Logo"}/>*/}
            </div>
        </div>
    )
}