import React, {useEffect, useState} from 'react';
import axios from "axios";
import ApiRepository from "../repository/apiRepository";

export const VideoContext = React.createContext({
    url: null,
    onChangeVideo: (value) => {},
    onHide: () => {}
})

export const VideoContextProvider = props => {
    const [videoData, setVideoData] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        // const items = localStorage.getItem("videos")
        // if (localStorage.getItem("videos")) {
        //     const videos = JSON.parse(items);
        //     if (videos) {
        //         setVideoData(videos);
        //     }
        // } else {
        ApiRepository.getSignByLangCode(localStorage.getItem("locale")).then((response)=>{
            setVideoData(response.data)
            localStorage.setItem("videos", JSON.stringify(response.data))
        }).catch(() => setVideoData(null));


        // }

    },[])

    const onChange = (value) => {
        if (videoData) {
            const url = videoData[value];
            setUrl(url);
        }
    }

    const onHide = () => {
        if (videoData) {
            setUrl(null);
        }
    }


    return (
        <VideoContext.Provider value={{
            url: url,
            onChangeVideo: onChange,
            onHide:onHide
        }}>
            {props.children}
        </VideoContext.Provider>
    )
}