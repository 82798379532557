import react from 'react';
import {useTranslation} from "react-i18next";

const ContactPage = (props) => {

    const {t} = useTranslation();

    return (
        <div className={"container-fluid"}>
            <div className="row header-infographic" style={{"background-image": "url(/images/heroes/contact.jpg)"}}>
                <div className="container pt-4">
                    <div className="row g-mb-60 text-center g-heading-v7" >
                        <div className="col-8 offset-2">
                            <h2 className="">
                        <span className="g-text-height-lg" style={{"text-shadow": "black 0.05em 0.05em 0.05em"}}>
                            {t('contact')}
                        </span>
                            </h2>
                        </div>
                    </div>
                </div>

            </div>

            <section id="news" className="mb-2 mt-2" style={{"background-color":"white"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src="/images/logo_moepp.PNG" className="img img-responsive" style={{"width":"250px"}}/>
                        </div>
                        <div className="col-md-8">
                            <h4><strong>{t('contact.name')}</strong></h4>
                            <div><i className="fa fa-map-marker"
                                   aria-hidden="true"></i> {t('contact.address.pagevalue')}</div>
                            <div><i className="fa fa-map-marker" aria-hidden="true"></i> {t('contact.city')}
                            </div>
                            <div><i className="fa fa-phone"
                                   aria-hidden="true"></i> {t('contact.phone.pagevalue')}</div>
                            <div><i className="fa fa-envelope-o"
                                   aria-hidden="true"></i> {t('contact.email.pagevalue')}</div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4">
                    <div className="row">
                        <div className="col-md-12">
                            <h4><strong>{t('zlp.title')}</strong></h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{"background-color":"white"}}>
                            <div><b>{t('zlp.person1.fullname')}</b></div>
                            <div><i className="fa fa-briefcase" aria-hidden="true"></i> {t('zlp.person1.wp')}
                            </div>
                            <div><i className="fa fa-envelope-o"
                                   aria-hidden="true"></i> {t('zlp.person1.email')}</div>
                        </div>
                        <div className="col-md-4">
                            <div><b>{t('zlp.person2.fullname')}</b></div>
                            <div><i className="fa fa-briefcase" aria-hidden="true"></i> {t('zlp.person2.wp')}
                            </div>
                            <div><i className="fa fa-envelope-o"
                                   aria-hidden="true"></i> {t('zlp.person2.email')}</div>
                        </div>
                        <div className="col-md-4">
                            <div><b>{t('zlp.person3.fullname')}</b></div>
                            <div><i className="fa fa-briefcase" aria-hidden="true"></i> {t('zlp.person3.wp')}
                            </div>
                            <div><i className="fa fa-envelope-o"
                                   aria-hidden="true"></i> {t('zlp.person3.email')}</div>
                        </div>
                    </div>
                </div>



            </section>
        </div>
    )
}

export default ContactPage;