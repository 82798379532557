import axios from "../../config/axios";
import apiRepository from "../../repository/apiRepository";

export const SearchRepository = {
    searchMock: (term) => {
      return axios.get('http://localhost:3000//mock.json')
    },
    searchTermInDms: (term) => {
        return axios.get(`https://dms.klimatskipromeni.mk/proxy/alfresco/api/-default-/public/alfresco/versions/1/queries/nodes?term=${term}`);
    },
    searchTermInWeb: (term) => {
        return apiRepository.searchTerm(term);
    }
};