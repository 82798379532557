import React, {useContext} from "react";
import {RiFontSize} from "react-icons/ri"
import {FaAmericanSignLanguageInterpreting, FaMousePointer} from "react-icons/fa"
import {GiSoundWaves} from 'react-icons/gi'
import {LinearProgress} from "@material-ui/core";
import {FiLink} from "react-icons/fi";
import {AiOutlineFontColors} from "react-icons/ai";
import {OptionContext} from "../../context/option-context";
import {Paragraph} from "../UI/Paragraph";
import {Heading} from "../UI/Heading";
import {CgArrowsH} from "react-icons/cg";
import {ImFont} from "react-icons/im";
import {MouseHover} from "../UI/MouseHover";
import './options.css';

export const OptionsMenu = props => {
    const optionsContext = useContext(OptionContext);

    return (
        <>
            <div style={{maxWidth: "330px", maxHeight: "calc(100vh - 120px)"}} className={"my-3"}>
                <div className={"border bg-white ms-auto card"} style={{maxHeight: "calc(100vh - 120px)"}}>
                    <div className={"card-header bg-green options-card-header text-center"}>
                        <MouseHover className={"text-center"} type={6}>Мени за достапност на содржини</MouseHover>
                    </div>
                    <div className={"card-body overflow-auto"} style={{maxHeight: "calc(100vh - 120px)"}}>
                        <div className={"row"}>

                            <div className={"col-md-6 col-sm-12 h-100 p-1"}
                                 onClick={() => optionsContext.onDyslexiaChange()}>
                                <div
                                    className={`border text-center pt-3 mh-75 ${optionsContext.dyslexia && "bg-primary bg-opacity-25"}`}>
                                    <ImFont size={40} classname="m-3 pb-5"/>
                                    <div className={"text-center item-text"}>
                                        <MouseHover className={"text-center"}>Дислексија</MouseHover>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-sm-12 h-100 p-1"}
                                 onClick={() => optionsContext.onTextSpacingChange()}>
                                <div
                                    className={`border text-center pt-2 mh-75 ${optionsContext.textSpacing && "bg-primary bg-opacity-25"}`}>
                                    <CgArrowsH size={50} classname="m-3 pb-5"/>
                                    <div className={"text-center item-text"}>
                                        <MouseHover className={"text-center"}>Растојание на текст</MouseHover>
                                    </div>

                                </div>
                            </div>

                            <div className={"col-md-6 col-sm-12 h-100 p-1"}
                                 onClick={() => optionsContext.onSignMarkChange()}>
                                <div
                                    className={`border text-center pt-2 mh-75  ${optionsContext.signMark && "bg-primary bg-opacity-25"}`}>
                                    <FaAmericanSignLanguageInterpreting size={50} classname="m-2"/>
                                    <div className={"text-center item-text"}>
                                        <MouseHover>Знаковен јазик</MouseHover>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-sm-12  h-100 p-1"}
                                 onClick={() => optionsContext.onSoundChange()}>
                                <div
                                    className={`border text-center  pt-2 mh-75 ${optionsContext.sound && "bg-primary bg-opacity-25"}`}>
                                    <GiSoundWaves size={50} classname="m-2"/>
                                    <div className={"text-center item-text"}>
                                        <MouseHover>Говорен асистент</MouseHover>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-6 col-sm-12 h-100 p-1 pb-0"}
                                 onClick={() => optionsContext.onHighlightTextChange()}>
                                <div
                                    className={`border text-center  pt-2 mh-75 ${optionsContext.highlightText && "bg-primary bg-opacity-25"}`}>
                                    <AiOutlineFontColors size={50} classname="m-2"/>
                                    <div className={"text-center item-text"}>
                                        <MouseHover>Одбележи текст</MouseHover>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-6 col-sm-12 h-100 p-1"}
                                 onClick={() => optionsContext.onLinkMarkChange()}>
                                <div
                                    className={`border text-center pt-2 mh-75 ${optionsContext.linkMark && "bg-primary bg-opacity-25"}`}>

                                    <FiLink size={50} classname="m-2"/>
                                    <div className={"text-center item-text"}>
                                        <MouseHover>Означи линкови</MouseHover>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-sm-12 h-100 p-1"}
                                 onClick={() => optionsContext.incrementFontSize()}>
                                <div className={"border text-center pt-2 mh-75"}>
                                    <RiFontSize size={50} classname="m-2"/>
                                    <div className={"text-center item-text pb-0"}>
                                        <MouseHover>Зголеми фонт</MouseHover>
                                        <LinearProgress variant="determinate" value={optionsContext.fontSize * 25}/>
                                    </div>

                                </div>
                            </div>
                            <div className={"col-md-6 col-sm-12 h-100 p-1"}
                                 onClick={() => optionsContext.incrementMousePointer()}>
                                <div
                                    className={`border text-center pt-2 mh-75 ${optionsContext.mousePointer && "bg-primary bg-opacity-25"}`}>
                                    <FaMousePointer size={50} classname="m-2"/>
                                    <div className={"text-center item-text pb-0"}>
                                        <MouseHover>Зголеми маус</MouseHover>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}